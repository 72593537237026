export default {
    "title": "🇪🇸 Español",

    "很棒的住宿体验": "Gran experiencia de alojamiento",
    "清爽干净，维护良好": "Fresco, limpio y bien mantenido",
    "很棒的酒店和员工": "Gran hotel y personal",
    "价值符合预期": "El valor cumple con las expectativas",
    "卓越的": "Excepcional",

    "首页": "Inicio",
    "预订": "Reservar",
    "关于": "Acerca de",
    "发现": "Descubrir",
    "会员": "Miembro",
    "推荐计划": "Programa de Referidos",
    "我的": "Mi",
    "个人": "Personal",
    "推荐": "Recomendar",
    "事件": "Evento",
    "登出": "Cerrar sesión",
    "登录": "Iniciar sesión",
    "注册": "Registrar",
    "语言": "Idioma",
    "忘记密码": "Olvidé mi contraseña",
    "取消": "Cancelar",
    "确认": "Confirmar",
    "酒店": "Hotel",
    "银行卡列表": "Lista de tarjetas bancarias",
    "银行列表": "Lista de bancos",
    "持卡人姓名": "Nombre del titular de la tarjeta",
    "银行卡号": "Número de tarjeta",
    "支付密码": "Contraseña de pago",
    "提交": "Enviar",
    "Pix列表": "Lista de Pix",
    "Pix类型": "Tipo de Pix",
    "Pix账号": "Cuenta de Pix",
    "账号列表": "Lista de cuentas",
    "网络": "Red",
    "地址": "Dirección",
    "备注": "Notas",
    "充值金额": "Monto de recarga",
    "支付类型": "Tipo de pago",
    "支付银行": "Banco de pago",
    "USDT地址": "Dirección USDT",
    "二维码": "Código QR",
    "充值渠道": "Canal de recarga",
    "充值凭证": "Comprobante de recarga",
    "立即登录": "Iniciar sesión ahora",
    "用户名": "Nombre de usuario",
    "密码": "Contraseña",
    "邀请码": "Código de invitación",
    "确认密码": "Confirmar contraseña",
    "手机": "Teléfono",
    "金额": "Monto",
    "新增": "Añadir",
    "钱包余额": "Saldo de cuenta",
    "单价": "Precio unitario",
    "数量": "Cantidad",
    "总额": "Total",
    "奖励": "Recompensa",
    "手机号码": "Número de teléfono",
    "修改密码": "Cambiar contraseña",
    "登录密码": "Contraseña de inicio de sesión",
    "当前密码": "Contraseña actual",
    "新密码": "Nueva contraseña",
    "确认新密码": "Confirmar nueva contraseña",
    "保存修改": "Guardar cambios",
    "充值": "Recargar",
    "复制": "Copiar",
    "分享链接": "Compartir enlace",
    "升级": "Actualizar",
    "登录时间": "Hora de inicio de sesión",
    "信誉分": "Puntos de crédito",
    "提现": "Retirar",
    "提现账号": "Cuenta de retiro",
    "你确定要删除此账号吗": "¿Estás seguro de que deseas eliminar esta cuenta?",
    "你确定要删除此地址吗": "¿Estás seguro de que deseas eliminar esta dirección?",
    "提示": "Aviso",
    "是": "Sí",
    "否": "No",
    "全部": "Todo",
    "待处理": "Pendiente",
    "冻结中": "Congelado",
    "已完成": "Completado",
    "我们的伙伴": "Nuestros socios",
    "关于我们": "Sobre nosotros",
    "创建时间": "Fecha de creación",
    "你确定要登出当前账号吗？": "¿Estás seguro de que deseas cerrar sesión en esta cuenta?",
    "正在寻找酒店": "Buscando hoteles",
    "旅程价格": "Precio del viaje",
    "佣金": "Comisión",
    "搜索": "Buscar",
    "评分": "Calificación",
    "评论": "Comentarios",
    "评论2": "Comentarios",
    "你还没有设置支付密码，请前往设置": "Aún no has configurado una contraseña de pago, por favor ve a configuración",
    "请输入": "Por favor ingrese",
    "请选择": "Por favor seleccione",
    "账户明细": "Detalles de la cuenta",
    "等级": "Nivel",
    "每日订单": "Pedidos diarios",
    "设置": "Configuración",
    "语言设置": "Configuración de idioma",
    "客服": "Servicio al cliente",
    "投诉": "Queja",
    "投诉客户服务": "Quejarse del servicio al cliente",
    "帮助": "Ayuda",
    "复制成功": "Copia exitosa",
    "位置、中心": "Ubicación, centro",
    "你好": "Hola",
    "我的账户": "Mi cuenta",
    "头像": "Avatar",
    "昵称": "Apodo",
    "设置昵称": "Configurar apodo",
    "输入酒店": "Ingresar hotel",
    "住宿问答": "Preguntas sobre alojamiento",
    "如果您想了解更多住宿信息，请查看其他房客的问题": "Si desea conocer más información sobre el alojamiento, consulte las preguntas de otros huéspedes",
    "酒店问答_Q1": "¿Cuál es la diferencia entre una habitación doble y una habitación con dos camas?",
    "酒店问答_A1": "Una habitación doble tiene una cama de matrimonio, mientras que una habitación con dos camas tiene dos camas individuales. Si la habitación se llama doble/dos camas, puede configurarse como cualquiera de los dos tipos. ¿Esta información es útil?",
    "酒店问答_Q2": "¿Qué incluye el precio?",
    "酒店问答_A2": "Puede verificar si hay otros cargos incluidos, como desayuno, impuestos o tarifas de servicio. Esta información también estará en su correo electrónico de confirmación después de la reserva, y también puede ver su reserva en su cuenta.",
    "立即预订可享受酒店特别折扣": "Reserve ahora para disfrutar de descuentos especiales en hoteles",
    "最低": "Mínimo",
    "折扣": "Descuento",
    "订单时间": "Hora del pedido",
    "产品": "Producto",
    "订单数量": "Cantidad de pedidos",
    "提交订单": "Enviar pedido",
    "可用余额": "Saldo disponible",
    "冻结金额": "Monto congelado",
    "佣金比例": "Tasa de comisión",
    "获得佣金": "Obtener comisión",
    "完成订单": "Pedido completado",
    "请至少选择一项": "Por favor selecciona al menos uno",
    "请先选择星星": "Por favor elige estrellas primero",
    "公告": "Anuncio",
    "邀请好友": "Invitar amigos",
    "邀请好友赚取佣金": "Invita amigos para ganar comisiones",
    "条款&协议": "Términos y condiciones",
    "注册协议": "Acuerdo de registro",
    "我已阅读并同意": "He leído y acepto",
    "加载中...": "Cargando...",
    "没有更多数据": "No hay más datos",
}