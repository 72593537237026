import { ref } from "vue";

const defaultDuration = ref(2500)
export var toastVisible = ref(false)

export var optionRef = ref({
    type: '',
    title: '',
    duration: 2500
})

export const toast = (options) => {
    if(toastVisible.value) return
    if (typeof options === 'string') {
        optionRef.value.title = options
        optionRef.value.type = 'error'
        optionRef.value.duration = defaultDuration.value
    }else {
        optionRef.value.title = options.title
        optionRef.value.type = options.type||'info'
        optionRef.value.duration = options.duration||defaultDuration.value
    }
    setTimeout(() => {
        toastVisible.value = true;
        setTimeout(() => {
            toastVisible.value = false
        }, optionRef.value.duration)
    }, 100);
}