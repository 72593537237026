export default {
    "title": "🇹🇼 繁體中文",

    "很棒的住宿体验": "很棒的住宿體驗",
    "清爽干净，维护良好": "清爽乾淨，維護良好",
    "很棒的酒店和员工": "很棒的飯店和員工",
    "价值符合预期": "價值符合預期",
    "卓越的": "卓越的",

    "首页": "首頁",
    "预订": "預訂",
    "关于": "關於",
    "发现": "發現",
    "会员": "會員",
    "推荐计划": "推薦計劃",
    "我的": "我的",
    "个人": "個人",
    "推荐": "推薦",
    "事件": "事件",
    "登出": "登出",
    "登录": "登錄",
    "注册": "註冊",
    "语言": "語言",
    "忘记密码": "忘記密碼",
    "取消": "取消",
    "确认": "確認",
    "酒店": "飯店",
    "银行卡列表": "銀行卡列表",
    "银行列表": "銀行列表",
    "持卡人姓名": "持卡人姓名",
    "银行卡号": "銀行卡號",
    "支付密码": "支付密碼",
    "提交": "提交",
    "Pix列表": "Pix列表",
    "Pix类型": "Pix類型",
    "Pix账号": "Pix帳號",
    "账号列表": "帳號列表",
    "网络": "網絡",
    "地址": "地址",
    "备注": "備註",
    "充值金额": "充值金額",
    "支付类型": "支付類型",
    "支付银行": "支付銀行",
    "USDT地址": "USDT地址",
    "二维码": "二維碼",
    "充值渠道": "充值渠道",
    "充值凭证": "充值憑證",
    "立即登录": "立即登錄",
    "用户名": "用戶名",
    "密码": "密碼",
    "邀请码": "邀請碼",
    "确认密码": "確認密碼",
    "手机": "手機",
    "金额": "金額",
    "新增": "新增",
    "钱包余额": "帳戶餘額",
    "单价": "單價",
    "数量": "數量",
    "总额": "總額",
    "奖励": "獎勵",
    "手机号码": "手機號碼",
    "修改密码": "修改密碼",
    "登录密码": "登錄密碼",
    "当前密码": "當前密碼",
    "新密码": "新密碼",
    "确认新密码": "確認新密碼",
    "保存修改": "保存修改",
    "充值": "充值",
    "复制": "複製",
    "分享链接": "分享鏈接",
    "升级": "升級",
    "登录时间": "登錄時間",
    "信誉分": "信用分",
    "提现": "提現",
    "提现账号": "提現帳號",
    "你确定要删除此账号吗": "你確定要刪除此帳號嗎",
    "你确定要删除此地址吗": "你確定要刪除此地址嗎",
    "提示": "提示",
    "是": "是",
    "否": "否",
    "全部": "全部",
    "待处理": "待處理",
    "冻结中": "凍結中",
    "已完成": "已完成",
    "我们的伙伴": "我們的夥伴",
    "关于我们": "關於我們",
    "创建时间": "創建時間",
    "你确定要登出当前账号吗？": "你確定要登出當前帳號嗎？",
    "正在寻找酒店": "正在尋找飯店",
    "旅程价格": "旅程價格",
    "佣金": "佣金",
    "搜索": "搜尋",
    "评分": "評分",
    "评论": "評論",
    "评论2": "評論",
    "你还没有设置支付密码，请前往设置": "你還沒有設置支付密碼，請前往設置",
    "请输入": "請輸入",
    "请选择": "請選擇",
    "账户明细": "帳戶明細",
    "等级": "等級",
    "每日订单": "每日訂單",
    "设置": "設置",
    "语言设置": "語言設置",
    "客服": "客服",
    "投诉": "投訴",
    "投诉客户服务": "投訴客戶服務",
    "帮助": "幫助",
    "复制成功": "複製成功",
    "位置、中心": "位置、中心",
    "你好": "你好",
    "我的账户": "我的帳戶",
    "头像": "頭像",
    "昵称": "暱稱",
    "设置昵称": "設置暱稱",
    "输入酒店": "輸入飯店",
    "住宿问答": "住宿問答",
    "如果您想了解更多住宿信息，请查看其他房客的问题": "如果您想了解更多住宿信息，請查看其他房客的問題",
    "酒店问答_Q1": "雙人房和雙床房有什麼區別？",
    "酒店问答_A1": "雙人間配有一張雙人床，雙床間配有兩張單人床。如果房間稱為雙人間/雙床間，則可以設置為任一類型。這則信息有幫助嗎？",
    "酒店问答_Q2": "價格包含什麼？",
    "酒店问答_A2": "您可以查看是否包含其他費用，例如早餐、稅費或服務費。這些信息也可以在您預訂後的確認電子郵件中找到，您也可以在您的帳戶中查看您的預訂。",
    "立即预订可享受酒店特别折扣": "立即預訂可享受飯店特別折扣",
    "最低": "最低",
    "折扣": "折扣",
    "订单时间": "訂單時間",
    "产品": "產品",
    "订单数量": "訂單數量",
    "提交订单": "提交訂單",
    "可用余额": "可用餘額",
    "冻结金额": "凍結金額",
    "佣金比例": "佣金比例",
    "获得佣金": "獲得佣金",
    "完成订单": "完成訂單",
    "请至少选择一项": "請至少選擇一項",
    "请先选择星星": "請先選擇星星",
    "公告": "公告",
    "邀请好友": "邀請好友",
    "邀请好友赚取佣金": "邀請好友賺取佣金",
    "条款&协议": "條款&協議",
    "注册协议": "註冊協議",
    "我已阅读并同意": "我已閱讀並同意",
    "加载中...": "加載中...",
    "没有更多数据": "沒有更多資料",
}