export default {
    "title": "🇩🇪 Deutsch",

    "很棒的住宿体验": "Tolle Unterkunftserfahrung",
    "清爽干净，维护良好": "Sauber, frisch und gut gepflegt",
    "很棒的酒店和员工": "Tolles Hotel und Mitarbeiter",
    "价值符合预期": "Wert entspricht den Erwartungen",
    "卓越的": "Hervorragend",

    "首页": "Startseite",
    "预订": "Buchung",
    "关于": "Über",
    "发现": "Entdecken",
    "会员": "Mitglied",
    "推荐计划": "Empfehlungsprogramm",
    "我的": "Mein",
    "个人": "Persönlich",
    "推荐": "Empfehlung",
    "事件": "Ereignisse",
    "登出": "Abmelden",
    "登录": "Anmelden",
    "注册": "Registrieren",
    "语言": "Sprache",
    "忘记密码": "Passwort vergessen",
    "取消": "Abbrechen",
    "确认": "Bestätigen",
    "酒店": "Hotel",
    "银行卡列表": "Kartenliste",
    "银行列表": "Bankenliste",
    "持卡人姓名": "Name des Karteninhabers",
    "银行卡号": "Kartennummer",
    "支付密码": "Zahlungspasswort",
    "提交": "Einreichen",
    "Pix列表": "Pix-Liste",
    "Pix类型": "Pix-Typ",
    "Pix账号": "Pix-Konto",
    "账号列表": "Kontoliste",
    "网络": "Netzwerk",
    "地址": "Adresse",
    "备注": "Bemerkung",
    "充值金额": "Aufladebetrag",
    "支付类型": "Zahlungsart",
    "支付银行": "Zahlungsbank",
    "USDT地址": "USDT-Adresse",
    "二维码": "QR-Code",
    "充值渠道": "Aufladungskanal",
    "充值凭证": "Aufladebeleg",
    "立即登录": "Jetzt anmelden",
    "用户名": "Benutzername",
    "密码": "Passwort",
    "邀请码": "Einladungs-Code",
    "确认密码": "Passwort bestätigen",
    "手机": "Handy",
    "金额": "Betrag",
    "新增": "Neu hinzufügen",
    "钱包余额": "Kontostand",
    "单价": "Einzelpreis",
    "数量": "Menge",
    "总额": "Gesamtbetrag",
    "奖励": "Belohnung",
    "手机号码": "Handynummer",
    "修改密码": "Passwort ändern",
    "登录密码": "Anmelde-Passwort",
    "当前密码": "Aktuelles Passwort",
    "新密码": "Neues Passwort",
    "确认新密码": "Neues Passwort bestätigen",
    "保存修改": "Änderungen speichern",
    "充值": "Aufladen",
    "复制": "Kopieren",
    "分享链接": "Link teilen",
    "升级": "Upgrade",
    "登录时间": "Anmeldezeit",
    "信誉分": "Kreditpunkte",
    "提现": "Abheben",
    "提现账号": "Abhebungs-Konto",
    "你确定要删除此账号吗": "Sind Sie sicher, dass Sie dieses Konto löschen möchten?",
    "你确定要删除此地址吗": "Sind Sie sicher, dass Sie diese Adresse löschen möchten?",
    "提示": "Hinweis",
    "是": "Ja",
    "否": "Nein",
    "全部": "Alle",
    "待处理": "Ausstehend",
    "冻结中": "Gefroren",
    "已完成": "Abgeschlossen",
    "我们的伙伴": "Unsere Partner",
    "关于我们": "Über uns",
    "创建时间": "Erstellungszeit",
    "你确定要登出当前账号吗？": "Sind Sie sicher, dass Sie sich von diesem Konto abmelden möchten?",
    "正在寻找酒店": "Suche nach Hotels",
    "旅程价格": "Reisepreis",
    "佣金": "Provision",
    "搜索": "Suche",
    "评分": "Bewertung",
    "评论": "Kommentar",
    "评论2": "Kommentar",
    "你还没有设置支付密码，请前往设置": "Sie haben noch kein Zahlungspasswort eingerichtet, bitte gehen Sie zu den Einstellungen.",
    "请输入": "Bitte eingeben",
    "请选择": "Bitte auswählen",
    "账户明细": "Kontodetails",
    "等级": "Stufe",
    "每日订单": "Tägliche Bestellungen",
    "设置": "Einstellungen",
    "语言设置": "Spracheinstellungen",
    "客服": "Kundendienst",
    "投诉": "Beschwerde",
    "投诉客户服务": "Kundendienst beschweren",
    "帮助": "Hilfe",
    "复制成功": "Erfolgreich kopiert",
    "位置、中心": "Standort, Zentrum",
    "你好": "Hallo",
    "我的账户": "Mein Konto",
    "头像": "Avatar",
    "昵称": "Spitzname",
    "设置昵称": "Spitznamen einstellen",
    "输入酒店": "Hotel eingeben",
    "住宿问答": "Fragen zum Aufenthalt",
    "如果您想了解更多住宿信息，请查看其他房客的问题": "Wenn Sie mehr Informationen über den Aufenthalt wünschen, schauen Sie sich die Fragen anderer Gäste an.",
    "酒店问答_Q1": "Was ist der Unterschied zwischen einem Doppelzimmer und einem Zweibettzimmer?",
    "酒店问答_A1": "Ein Doppelzimmer hat ein großes Bett, während ein Zweibettzimmer zwei Einzelbetten hat. Wenn das Zimmer als Doppelzimmer/Zweibettzimmer bezeichnet wird, kann es als eines von beiden eingerichtet werden. Ist diese Information hilfreich?",
    "酒店问答_Q2": "Was ist im Preis enthalten?",
    "酒店问答_A2": "Sie können überprüfen, ob zusätzliche Gebühren wie Frühstück, Steuern oder Servicegebühren enthalten sind. Diese Informationen finden Sie auch in der Bestätigungs-E-Mail nach Ihrer Buchung, und Sie können sie auch in Ihrem Konto überprüfen.",
    "立即预订可享受酒店特别折扣": "Buchen Sie jetzt und sichern Sie sich besondere Rabatte auf das Hotel.",
    "最低": "Minimal",
    "折扣": "Rabatt",
    "订单时间": "Bestellzeit",
    "产品": "Produkt",
    "订单数量": "Bestellmenge",
    "提交订单": "Bestellung einreichen",
    "可用余额": "Verfügbarer Saldo",
    "冻结金额": "Eingefrorener Betrag",
    "佣金比例": "Provisionssatz",
    "获得佣金": "Provision verdienen",
    "完成订单": "Bestellung abschließen",
    "请至少选择一项": "Bitte wählen Sie mindestens eine Option",
    "请先选择星星": "Bitte wählen Sie zuerst einen Stern",
    "公告": "Ankündigung",
    "邀请好友": "Freunde einladen",
    "邀请好友赚取佣金": "Laden Sie Freunde ein und verdienen Sie Provisionen",
    "条款&协议": "Nutzungsbedingungen & Vereinbarung",
    "注册协议": "Registrierungsvereinbarung",
    "我已阅读并同意": "Ich habe gelesen und stimme zu",
    "加载中...": "Wird geladen...",
    "没有更多数据": "Keine weiteren Daten",
}