export default {
    "title": "🇷🇺 Русский",

    "很棒的住宿体验": "Отличный опыт проживания",
    "清爽干净，维护良好": "Свежо, чисто и хорошо ухоженно",
    "很棒的酒店和员工": "Отличный отель и персонал",
    "价值符合预期": "Цена соответствует ожиданиям",
    "卓越的": "Выдающийся",

    "首页": "Главная",
    "预订": "Бронирование",
    "关于": "О нас",
    "发现": "Открыть",
    "会员": "Членство",
    "推荐计划": "Рекомендательная программа",
    "我的": "Мой",
    "个人": "Личный",
    "推荐": "Рекомендация",
    "事件": "Событие",
    "登出": "Выход",
    "登录": "Вход",
    "注册": "Регистрация",
    "语言": "Язык",
    "忘记密码": "Забыли пароль",
    "取消": "Отмена",
    "确认": "Подтвердить",
    "酒店": "Отель",
    "银行卡列表": "Список банковских карт",
    "银行列表": "Список банков",
    "持卡人姓名": "Имя держателя карты",
    "银行卡号": "Номер банковской карты",
    "支付密码": "Пароль для оплаты",
    "提交": "Отправить",
    "Pix列表": "Список Pix",
    "Pix类型": "Тип Pix",
    "Pix账号": "Аккаунт Pix",
    "账号列表": "Список аккаунтов",
    "网络": "Сеть",
    "地址": "Адрес",
    "备注": "Примечание",
    "充值金额": "Сумма пополнения",
    "支付类型": "Тип оплаты",
    "支付银行": "Банк для оплаты",
    "USDT地址": "Адрес USDT",
    "二维码": "QR-код",
    "充值渠道": "Канал пополнения",
    "充值凭证": "Квитанция о пополнении",
    "立即登录": "Войти сейчас",
    "用户名": "Имя пользователя",
    "密码": "Пароль",
    "邀请码": "Промокод",
    "确认密码": "Подтвердите пароль",
    "手机": "Телефон",
    "金额": "Сумма",
    "新增": "Добавить",
    "钱包余额": "Баланс счета",
    "单价": "Цена за единицу",
    "数量": "Количество",
    "总额": "Итого",
    "奖励": "Награда",
    "手机号码": "Номер телефона",
    "修改密码": "Сменить пароль",
    "登录密码": "Пароль для входа",
    "当前密码": "Текущий пароль",
    "新密码": "Новый пароль",
    "确认新密码": "Подтвердите новый пароль",
    "保存修改": "Сохранить изменения",
    "充值": "Пополнение",
    "复制": "Копировать",
    "分享链接": "Поделиться ссылкой",
    "升级": "Обновление",
    "登录时间": "Время входа",
    "信誉分": "Рейтинг",
    "提现": "Вывод средств",
    "提现账号": "Аккаунт для вывода",
    "你确定要删除此账号吗": "Вы уверены, что хотите удалить этот аккаунт?",
    "你确定要删除此地址吗": "Вы уверены, что хотите удалить этот адрес?",
    "提示": "Сообщение",
    "是": "Да",
    "否": "Нет",
    "全部": "Все",
    "待处理": "В ожидании",
    "冻结中": "Заморожено",
    "已完成": "Завершено",
    "我们的伙伴": "Наши партнеры",
    "关于我们": "О нас",
    "创建时间": "Время создания",
    "你确定要登出当前账号吗？": "Вы уверены, что хотите выйти из текущего аккаунта?",
    "正在寻找酒店": "Поиск отелей...",
    "旅程价格": "Цена путешествия",
    "佣金": "Комиссия",
    "搜索": "Поиск",
    "评分": "Оценка",
    "评论": "Отзыв",
    "评论2": "Отзыв",
    "你还没有设置支付密码，请前往设置": "Вы еще не установили пароль для оплаты, пожалуйста, перейдите в настройки.",
    "请输入": "Пожалуйста, введите",
    "请选择": "Пожалуйста, выберите",
    "账户明细": "Детали счета",
    "等级": "Уровень",
    "每日订单": "Ежедневные заказы",
    "设置": "Настройки",
    "语言设置": "Настройки языка",
    "客服": "Служба поддержки",
    "投诉": "Жалоба",
    "投诉客户服务": "Жалоба на службу поддержки",
    "帮助": "Помощь",
    "复制成功": "Копирование успешно",
    "位置、中心": "Местоположение, центр",
    "你好": "Здравствуйте",
    "我的账户": "Мой аккаунт",
    "头像": "Аватар",
    "昵称": "Псевдоним",
    "设置昵称": "Установить псевдоним",
    "输入酒店": "Введите отель",
    "住宿问答": "Вопросы и ответы по размещению",
    "如果您想了解更多住宿信息，请查看其他房客的问题": "Если вы хотите узнать больше информации о размещении, пожалуйста, посмотрите вопросы других гостей.",
    "酒店问答_Q1": "В чем разница между двухместным номером и номером с двумя кроватями?",
    "酒店问答_A1": "В двухместном номере стоит одна двуспальная кровать, а в номере с двумя кроватями - две односпальные. Если номер называется двухместным/двухкроватным, он может быть установлен как любой тип. Это полезная информация?",
    "酒店问答_Q2": "Что включено в цену?",
    "酒店问答_A2": "Вы можете проверить, включены ли другие расходы, такие как завтрак, налоги или сборы за услуги. Эта информация также будет указана в подтверждающем электронном письме после бронирования, и вы сможете просмотреть свое бронирование в своем аккаунте.",
    "立即预订可享受酒店特别折扣": "Забронируйте сейчас и получите специальные скидки на отели!",
    "最低": "Минимальный",
    "折扣": "Скидка",
    "订单时间": "Время заказа",
    "产品": "Продукт",
    "订单数量": "Количество заказов",
    "提交订单": "Подтвердить заказ",
    "可用余额": "Доступный баланс",
    "冻结金额": "Замороженная сумма",
    "佣金比例": "Процент комиссии",
    "获得佣金": "Получить комиссию",
    "完成订单": "Завершить заказ",
    "请至少选择一项": "Пожалуйста, выберите хотя бы один пункт",
    "请先选择星星": "Пожалуйста, сначала выберите звезды",
    "公告": "Объявление",
    "邀请好友": "Пригласить друга",
    "邀请好友赚取佣金": "Пригласите друзей и зарабатывайте комиссию",
    "条款&协议": "Условия и соглашения",
    "注册协议": "Соглашение о регистрации",
    "我已阅读并同意": "Я прочитал и согласен",
    "加载中...": "Загрузка...",
    "没有更多数据": "Нет больше данных",
}