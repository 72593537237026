import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      tabar: true,
      loginRequired: true
    }
  },
  {
    path: '/task',
    name: 'task',
    component: () => import('../views/TaskView.vue'),
    meta: {
      tabar: true,
      loginRequired: true
    }
  },
  {
    path: '/event',
    name: 'event',
    component: () => import('../views/EventView.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/UserView.vue'),
    meta: {
      tabar: true,
      loginRequired: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: {}
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterView.vue'),
    meta: {}
  },
  {
    path: '/empty',
    name: 'empty',
    component: () => import('../views/EmptyView.vue'),
    meta: {}
  },
  {
    path: '/invite',
    name: 'invite',
    component: () => import('../views/InviteView.vue'),
    meta: {
      loginRequired: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/ProfileView.vue'),
    meta: {
      loginRequired: true
    }
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: () => import('../views/WithdrawView.vue'),
    meta: {
      loginRequired: true
    }
  },
  {
    path: '/withdraw_account',
    name: 'withdraw_account',
    component: () => import('../views/WithdrawAccountView.vue'),
    meta: {
      loginRequired: true
    }
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import('../views/RechargeView.vue'),
    meta: {
      loginRequired: true
    }
  },
  {
    path: '/set_paypwd',
    name: 'set_paypwd',
    component: () => import('../views/SetPaypwdView.vue'),
    meta: {
      loginRequired: true
    }
  },
  {
    path: '/set_loginpwd',
    name: 'set_loginpwd',
    component: () => import('../views/SetLoginpwdView.vue'),
    meta: {
      loginRequired: true
    }
  },
  {
    path: '/set_language',
    name: 'set_language',
    component: () => import('../views/SetLanguageView.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/ServiceView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: {
      loginRequired: true
    }
  },
  {
    path: '/account_details',
    name: 'account_details',
    component: () => import('../views/AccountDetailsView.vue'),
    meta: {
      loginRequired: true
    }
  },
  {
    path: '/vip',
    name: 'vip',
    component: () => import('../views/VipView.vue'),
    meta: {
      loginRequired: true
    }
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('../views/SettingView.vue'),
    meta: {
      loginRequired: true
    }
  },
  {
    path: '/FAQs',
    name: 'FAQs',
    component: () => import('../views/FAQsView.vue'),
    meta: {
      loginRequired: true
    }
  },
  {
    path: '/license',
    name: 'license',
    component: () => import('../views/LicenseView.vue')
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('../views/RegistrationView.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
