import { computed, onMounted, ref } from 'vue'
import { get, post } from '@/request'

const APPNAME = process.env.VUE_APP_NAME

export const appNameRef = ref(APPNAME)

export async function uploadBase64ImageFunc(image) {
    let res = await post({url: '/v1/file/base64', data: { image: image }}, {loading: true })
    if(res.code == 200) {
        return res.data.url
    }else {
        return ''
    }
}

const configRef = ref({})
export function configMOdel(init) {
    const bannerListRef = computed(() => configRef.value.banners || [])
    const webListRef = computed(() => configRef.value.weblist || [])
    const customerUrlRef = computed(() => configRef.value.customer_url)
    const serviceTimeRef = computed(() => configRef.value.service_time)

    const refresh = async () => {
        const res = await get({ url: '/v1/site/config' })
        const data = res.data || {}
        configRef.value = data
    }

    const toCustomerURLFunc = () => {
        if(customerUrlRef.value) {
            window.open(customerUrlRef.value)
        }
    }

    onMounted(()=> {
        if(init) {
            refresh()
        }
    })

    return { bannerListRef, webListRef, customerUrlRef, serviceTimeRef, toCustomerURLFunc }
}