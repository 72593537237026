<script setup>
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const props = defineProps({
    title: String,
    hideHeader: Boolean,
    hideBack: Boolean,
    noPaddingTop: Boolean,
    backgroundColor: String,
    textColor: String
})

function back() {
    router.back()
}
</script>

<template>
    <div class="ctx-wrap text-default">
        <header v-if="!hideHeader" :class="noPaddingTop ? 'h-0' : 'h-11 sm:h-14'" class="w-full block z-40">
            <div :class="{
                'bg-default': !backgroundColor, 
                'text-default': !textColor
            }" :style="{
                backgroundColor: backgroundColor,
                color: textColor
            }" class="ctx-wrap h-11 sm:h-14 fixed left-1/2 top-0 z-40 -translate-x-1/2 flex items-center justify-center">
                <div class="h-full flex items-center absolute left-0 top-0">
                    <button v-if="!hideBack" @click="back()" class="px-2 h-full flex items-center">
                        <svg class="w-6 h-6" stroke="currentColor" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M31 36L19 24L31 12" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <slot name="left"></slot>
                </div>
                <div class="w-1/2 h-full flex items-center justify-center">
                    <p v-if="title" class="text-lg leading-5 font-bold text-center line-clamp-2">{{title}}</p>
                    <slot name="center"></slot>
                </div>
                <div class="h-full px-1.5 flex items-center absolute right-0 top-0">
                    <slot name="right"></slot>
                </div>
            </div>
        </header>
        <slot></slot>
    </div>
</template>