export default {
    "title": "🇮🇹 Italiano",

    "很棒的住宿体验": "Ottima esperienza di soggiorno",
    "清爽干净，维护良好": "Pulito, fresco e ben curato",
    "很棒的酒店和员工": "Ottimo hotel e personale",
    "价值符合预期": "Il valore è conforme alle aspettative",
    "卓越的": "Eccellente",

    "首页": "Home",
    "预订": "Prenotazione",
    "关于": "Informazioni",
    "发现": "Scopri",
    "会员": "Membri",
    "推荐计划": "Programma di riferimento",
    "我的": "Il mio",
    "个人": "Personale",
    "推荐": "Raccomandazione",
    "事件": "Eventi",
    "登出": "Disconnetti",
    "登录": "Accedi",
    "注册": "Registrati",
    "语言": "Lingua",
    "忘记密码": "Password dimenticata",
    "取消": "Annulla",
    "确认": "Conferma",
    "酒店": "Hotel",
    "银行卡列表": "Elenco carte bancarie",
    "银行列表": "Elenco banche",
    "持卡人姓名": "Nome del titolare della carta",
    "银行卡号": "Numero della carta",
    "支付密码": "Password di pagamento",
    "提交": "Invia",
    "Pix列表": "Elenco Pix",
    "Pix类型": "Tipo di Pix",
    "Pix账号": "Account Pix",
    "账号列表": "Elenco account",
    "网络": "Rete",
    "地址": "Indirizzo",
    "备注": "Nota",
    "充值金额": "Importo del deposito",
    "支付类型": "Tipo di pagamento",
    "支付银行": "Banca di pagamento",
    "USDT地址": "Indirizzo USDT",
    "二维码": "Codice QR",
    "充值渠道": "Canale di deposito",
    "充值凭证": "Ricevuta di deposito",
    "立即登录": "Accedi ora",
    "用户名": "Nome utente",
    "密码": "Password",
    "邀请码": "Codice invito",
    "确认密码": "Conferma password",
    "手机": "Telefono",
    "金额": "Importo",
    "新增": "Aggiungi nuovo",
    "钱包余额": "Saldo del wallet",
    "单价": "Prezzo unitario",
    "数量": "Quantità",
    "总额": "Totale",
    "奖励": "Ricompensa",
    "手机号码": "Numero di telefono",
    "修改密码": "Cambia password",
    "登录密码": "Password di accesso",
    "当前密码": "Password attuale",
    "新密码": "Nuova password",
    "确认新密码": "Conferma nuova password",
    "保存修改": "Salva modifiche",
    "充值": "Deposito",
    "复制": "Copia",
    "分享链接": "Condividi link",
    "升级": "Aggiornamento",
    "登录时间": "Orario di accesso",
    "信誉分": "Punteggio di credito",
    "提现": "Prelievo",
    "提现账号": "Account di prelievo",
    "你确定要删除此账号吗": "Sei sicuro di voler eliminare questo account?",
    "你确定要删除此地址吗": "Sei sicuro di voler eliminare questo indirizzo?",
    "提示": "Messaggio",
    "是": "Sì",
    "否": "No",
    "全部": "Tutti",
    "待处理": "In attesa",
    "冻结中": "Congelato",
    "已完成": "Completato",
    "我们的伙伴": "I nostri partner",
    "关于我们": "Chi siamo",
    "创建时间": "Data di creazione",
    "你确定要登出当前账号吗？": "Sei sicuro di voler disconnettere l'account attuale?",
    "正在寻找酒店": "In ricerca di hotel",
    "旅程价格": "Prezzo del viaggio",
    "佣金": "Commissione",
    "搜索": "Cerca",
    "评分": "Valutazione",
    "评论": "Commento",
    "评论2": "Commento",
    "你还没有设置支付密码，请前往设置": "Non hai ancora impostato la password di pagamento, vai alle impostazioni",
    "请输入": "Per favore inserisci",
    "请选择": "Per favore seleziona",
    "账户明细": "Dettagli account",
    "等级": "Livello",
    "每日订单": "Ordini giornalieri",
    "设置": "Impostazioni",
    "语言设置": "Impostazioni lingua",
    "客服": "Servizio clienti",
    "投诉": "Reclamo",
    "投诉客户服务": "Reclama servizio clienti",
    "帮助": "Aiuto",
    "复制成功": "Copia riuscita",
    "位置、中心": "Posizione, Centro",
    "你好": "Ciao",
    "我的账户": "Il mio account",
    "头像": "Avatar",
    "昵称": "Soprannome",
    "设置昵称": "Imposta soprannome",
    "输入酒店": "Inserisci hotel",
    "住宿问答": "Domande e risposte sull'alloggio",
    "如果您想了解更多住宿信息，请查看其他房客的问题": "Se desideri ulteriori informazioni sull'alloggio, controlla le domande degli altri ospiti",
    "酒店问答_Q1": "Qual è la differenza tra una camera doppia e una camera con letti singoli?",
    "酒店问答_A1": "Una camera doppia ha un letto matrimoniale, mentre una camera con letti singoli ha due letti singoli. Se la stanza è chiamata camera doppia/camera con letti singoli, può essere configurata come uno dei due tipi. Questa informazione è utile?",
    "酒店问答_Q2": "Cosa è incluso nel prezzo?",
    "酒店问答_A2": "Puoi vedere se sono inclusi costi aggiuntivi come colazione, tasse o spese di servizio. Queste informazioni possono essere trovate anche nell'e-mail di conferma dopo la prenotazione e puoi visualizzare la tua prenotazione nel tuo account.",
    "立即预订可享受酒店特别折扣": "Prenota ora per ottenere sconti speciali sugli hotel",
    "最低": "Minimo",
    "折扣": "Sconto",
    "订单时间": "Data dell'ordine",
    "产品": "Prodotto",
    "订单数量": "Quantità d'ordine",
    "提交订单": "Invia ordine",
    "可用余额": "Saldo disponibile",
    "冻结金额": "Importo congelato",
    "佣金比例": "Percentuale di commissione",
    "获得佣金": "Guadagna commissione",
    "完成订单": "Ordine completato",
    "请至少选择一项": "Seleziona almeno un'opzione",
    "请先选择星星": "Seleziona prima le stelle",
    "公告": "Annuncio",
    "邀请好友": "Invita amici",
    "邀请好友赚取佣金": "Invita amici per guadagnare commissioni",
    "条款&协议": "Termini & Condizioni",
    "注册协议": "Accordo di registrazione",
    "我已阅读并同意": "Ho letto e accetto",
    "加载中...": "Caricamento in corso...",
    "没有更多数据": "Nessun altro dato",
}