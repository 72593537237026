import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "@nutui/nutui/dist/style.css"
import './main.css'
import { Button, Drag, Image, InfiniteLoading, Locale, Picker, Popup, Checkbox, CheckboxGroup } from '@nutui/nutui'
import enUS from '@nutui/nutui/dist/packages/locale/lang/en-US'

Locale.use('en-US', enUS)

// UI
import IconAdd from './components/IconAdd.vue'
import IconArrowDownS from './components/IconArrowDownS.vue'
import IconArrowRightS from './components/IconArrowRightS.vue'
import IconArrowUpS from './components/IconArrowUpS.vue'
import IconChevronRight from './components/IconChevronRight.vue'
import IconStar from './components/IconStar.vue'
import UiPage from './components/UiPage.vue'
import UiService from './components/UiService.vue'

const app = createApp(App)

app.use(Button).use(Drag).use(Image).use(InfiniteLoading).use(Picker).use(Popup).use(Checkbox).use(CheckboxGroup)

app.component('IconAdd', IconAdd)
app.component('IconArrowDownS', IconArrowDownS)
app.component('IconArrowRightS', IconArrowRightS)
app.component('IconArrowUpS', IconArrowUpS)
app.component('IconChevronRight', IconChevronRight)
app.component('IconStar', IconStar)
app.component('UiPage', UiPage)
app.component('UiService', UiService)

app.use(router).mount('#app')
