export default {
    "title": "🇺🇸 English",

    "很棒的住宿体验": "Great stay",
    "清爽干净，维护良好": "Crisp and clean, well maintained",
    "很棒的酒店和员工": "Amazing hotel & staff",
    "价值符合预期": "Value as expected",
    "卓越的": "Exceptional",

    "首页": "Home",
    "预订": "RESERVE",
    "关于": "About",
    "发现": "Discover",
    "会员": "Membership",
    "推荐计划": "Referral Program",
    "个人": "Profile",
    "我的": "Mine",
    "推荐": "Referral",
    "事件": "Event",
    "登出": "Sign Out",
    "登录": "Sign In",
    "注册": "Register",
    "语言": "Language",
    "忘记密码": "Forget",
    "取消": "Cancel",
    "确认": "Confirm",
    "酒店": "Hotel",
    "银行卡列表": "Bank Card List",
    "银行列表": "Bank List",
    "持卡人姓名": "Cardholder name",
    "银行卡号": "Bank Accound",
    "支付密码": "Pay Password",
    "提交": "Submit",
    "Pix列表": "Pix List",
    "Pix类型": "Pix Type",
    "Pix账号": "Pix Accound",
    "账号列表": "Accound List",
    "网络": "Network",
    "地址": "Address",
    "备注": "Note",
    "充值金额": "Recharge Amount",
    "支付类型": "Pay Type",
    "支付银行": "Pay Bank",
    "USDT地址": "USDT Address",
    "二维码": "QR Code",
    "充值渠道": "Recharge Channel",
    "充值凭证": "Recharge Voucher",
    "立即登录": "Login Now",
    "用户名": "Username",
    "密码": "Password",
    "邀请码": "Invite Code",
    "确认密码": "Confirm Password",
    "手机": "Mobile",
    "金额": "Amount",
    "新增": "New",
    "钱包余额": "Account Balance",
    "单价": "Price",
    "数量": "Number",
    "总额": "Total",
    "奖励": "Rewards",
    "手机号码": "Phone Number",
    "修改密码": "Change Password",
    "登录密码": "Login Password",
    "当前密码": "Current Password",
    "新密码": "New Password",
    "确认新密码": "Confirm New Password",
    "保存修改": "Save Changes",
    "充值": "Deposit",
    "复制": "Copy",
    "分享链接": "Share Link",
    "升级": "Upgrade",
    "登录时间": "Login Time",
    "信誉分": "Credibility",
    "提现": "Withdraw",
    "提现账号": "Withdraw Account",
    "你确定要删除此账号吗": "Are you sure delete this account",
    "你确定要删除此地址吗": "Are you sure delete this address",
    "提示": "Tips",
    "是": "Yes",
    "否": "No",
    "全部": "All",
    "待处理": "Pending",
    "冻结中": "Freezing",
    "已完成": "Completed",
    "我们的伙伴": "Our partners",
    "关于我们": "ABOUT US",
    "创建时间": "Created Time",
    "你确定要登出当前账号吗？": "Are you sure you want to log out of the current account?",
    "正在寻找酒店": "Looking for a hotel",
    "旅程价格": "Journey Price",
    "佣金": "Commissions",
    "搜索": "Search",
    "评分": "Rating",
    "评论": "Comment",
    "评论2": "Review",
    "你还没有设置支付密码，请前往设置": "You have not set a payment password, please go to settings",
    "请输入": "Type here",
    "请选择": "Select here",
    "账户明细": "Account Details",
    "等级": "Level",
    "每日订单": "Daily orders",
    "设置": "Setting",
    "语言设置": "Language Setting",
    "客服": "Customer Service",
    "投诉": "Complaints",
    "投诉客户服务": "Complain about customer service",
    "帮助": "Help",
    "复制成功": "Copy successful",
    "位置、中心": "Location,center",
    "你好": "Hi",
    "我的账户": "My Account",
    "头像": "Profile",
    "昵称": "Nickname",
    "设置昵称": "Set Nickname",
    "输入酒店": "Enter hotel",
    "住宿问答": "Accommodation Question and Answers",
    "如果您想了解更多住宿信息，请查看其他房客的问题": "if you would like more information about the accommodation, please see questions from other guests",
    "酒店问答_Q1": "What's the difference between a Double room and a Twin room?",
    "酒店问答_A1": "A Double Room has one double bed and a Twin Room has 2 single beds. If a room is called Double/Twin, it can be set up for either type. Was this information helpful?",
    "酒店问答_Q2": "What does the price include?",
    "酒店问答_A2": "You can check if other charges such as breakfast, taxes or service charges are included. This information can also be found in your confirmation email after booking, and you can also view your reservation in your account.",
    "立即预订可享受酒店特别折扣": "Book now with special hotel discounts",
    "最低": "MIN",
    "折扣": "OFF",
    "订单时间": "Order time",
    "产品": "Product",
    "订单数量": "Order number",
    "提交订单": "Submit order",
    "可用余额": "Free Balance",
    "冻结金额": "Freeze amount",
    "佣金比例": "Commission rate",
    "获得佣金": "Commission earn",
    "完成订单": "Complete order",
    "请至少选择一项": "Please select at least one",
    "请先选择星星": "Please select the star first",
    "公告": "Notice",
    "邀请好友": "Invite friends",
    "邀请好友赚取佣金": "Invite friends and earn commission",
    "条款&协议": "Terms & Agreements",
    "注册协议": "Registration Agreement",
    "我已阅读并同意": "I have read and agree",
    "加载中...": "Loading...",
    "没有更多数据": "No more data",
}