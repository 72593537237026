export default {
    "title": "🇹🇷 Türkçe",

    "很棒的住宿体验": "Harika konaklama deneyimi",
    "清爽干净，维护良好": "Temiz, ferah ve iyi korunmuş",
    "很棒的酒店和员工": "Harika otel ve personel",
    "价值符合预期": "Değer beklentileri karşılıyor",
    "卓越的": "Üstün",

    "首页": "Anasayfa",
    "预订": "Rezervasyon",
    "关于": "Hakkında",
    "发现": "Keşfet",
    "会员": "Üye",
    "推荐计划": "Tavsiye Programı",
    "我的": "Benim",
    "个人": "Kişisel",
    "推荐": "Tavsiye",
    "事件": "Etkinlikler",
    "登出": "Çıkış",
    "登录": "Giriş",
    "注册": "Kayıt Ol",
    "语言": "Dil",
    "忘记密码": "Şifremi Unuttum",
    "取消": "İptal",
    "确认": "Onayla",
    "酒店": "Otel",
    "银行卡列表": "Banka Kartı Listesi",
    "银行列表": "Banka Listesi",
    "持卡人姓名": "Kart Sahibi Adı",
    "银行卡号": "Banka Kartı Numarası",
    "支付密码": "Ödeme Şifresi",
    "提交": "Gönder",
    "Pix列表": "Pix Listesi",
    "Pix类型": "Pix Türü",
    "Pix账号": "Pix Hesabı",
    "账号列表": "Hesap Listesi",
    "网络": "Ağ",
    "地址": "Adres",
    "备注": "Not",
    "充值金额": "Yükleme Miktarı",
    "支付类型": "Ödeme Türü",
    "支付银行": "Ödeme Bankası",
    "USDT地址": "USDT Adresi",
    "二维码": "QR Kodu",
    "充值渠道": "Yükleme Kanalı",
    "充值凭证": "Yükleme Belgesi",
    "立即登录": "Hemen Giriş Yap",
    "用户名": "Kullanıcı Adı",
    "密码": "Şifre",
    "邀请码": "Davet Kodu",
    "确认密码": "Şifreyi Onayla",
    "手机": "Telefon",
    "金额": "Miktar",
    "新增": "Yeni Ekle",
    "钱包余额": "Hesap Bakiyesi",
    "单价": "Birim Fiyatı",
    "数量": "Adet",
    "总额": "Toplam",
    "奖励": "Ödül",
    "手机号码": "Telefon Numarası",
    "修改密码": "Şifre Değiştir",
    "登录密码": "Giriş Şifresi",
    "当前密码": "Mevcut Şifre",
    "新密码": "Yeni Şifre",
    "确认新密码": "Yeni Şifreyi Onayla",
    "保存修改": "Değişiklikleri Kaydet",
    "充值": "Yükleme",
    "复制": "Kopyala",
    "分享链接": "Paylaşım Linki",
    "升级": "Yükselt",
    "登录时间": "Giriş Zamanı",
    "信誉分": "İtibar Puanı",
    "提现": "Para Çekme",
    "提现账号": "Para Çekme Hesabı",
    "你确定要删除此账号吗": "Bu hesabı silmek istediğinize emin misiniz?",
    "你确定要删除此地址吗": "Bu adresi silmek istediğinize emin misiniz?",
    "提示": "İpucu",
    "是": "Evet",
    "否": "Hayır",
    "全部": "Hepsi",
    "待处理": "İşlemde",
    "冻结中": "Beklemede",
    "已完成": "Tamamlandı",
    "我们的伙伴": "Ortaklarımız",
    "关于我们": "Bizim Hakkımızda",
    "创建时间": "Oluşturulma Tarihi",
    "你确定要登出当前账号吗？": "Bu hesapla çıkış yapmak istediğinize emin misiniz?",
    "正在寻找酒店": "Otel aranıyor",
    "旅程价格": "Seyahat Ücreti",
    "佣金": "Komisyon",
    "搜索": "Ara",
    "评分": "Puan",
    "评论": "Yorum",
    "评论2": "Yorum",
    "你还没有设置支付密码，请前往设置": "Henüz bir ödeme şifresi ayarlamadınız, lütfen ayarlara gidin.",
    "请输入": "Lütfen giriniz",
    "请选择": "Lütfen seçiniz",
    "账户明细": "Hesap Detayları",
    "等级": "Seviye",
    "每日订单": "Günlük Sipariş",
    "设置": "Ayarlar",
    "语言设置": "Dil Ayarları",
    "客服": "Müşteri Hizmetleri",
    "投诉": "Şikayet",
    "投诉客户服务": "Müşteri hizmetlerine şikayet",
    "帮助": "Yardım",
    "复制成功": "Başarıyla kopyalandı",
    "位置、中心": "Konum, Merkez",
    "你好": "Merhaba",
    "我的账户": "Hesabım",
    "头像": "Profil Resmi",
    "昵称": "Takma Ad",
    "设置昵称": "Takma Ad Ayarla",
    "输入酒店": "Otel Giriniz",
    "住宿问答": "Konaklama SSS",
    "如果您想了解更多住宿信息，请查看其他房客的问题": "Daha fazla konaklama bilgisi almak istiyorsanız, diğer misafirlerin sorularına göz atın.",
    "酒店问答_Q1": "Çift kişilik oda ile iki yataklı oda arasındaki fark nedir?",
    "酒店问答_A1": "Çift kişilik odada bir çift kişilik yatak bulunurken, iki yataklı odada iki tek kişilik yatak vardır. Oda çift kişilik/iki yataklı olarak adlandırılıyorsa, her iki tipte de düzenlenebilir. Bu bilgi faydalı mı?",
    "酒店问答_Q2": "Fiyat neleri içeriyor?",
    "酒店问答_A2": "Kahvaltı, vergiler veya hizmet bedeli gibi ek ücretlerin dahil olup olmadığını kontrol edebilirsiniz. Bu bilgiler ayrıca rezervasyon onay e-postasında da bulunabilir ve aynı zamanda hesabınızda rezervasyonunuzu görebilirsiniz.",
    "立即预订可享受酒店特别折扣": "Hemen rezervasyon yaparak otelin özel indirimlerinden yararlanın.",
    "最低": "Minimum",
    "折扣": "İndirim",
    "订单时间": "Sipariş Zamanı",
    "产品": "Ürün",
    "订单数量": "Sipariş Miktarı",
    "提交订单": "Siparişi Gönder",
    "可用余额": "Kullanılabilir bakiye",
    "冻结金额": "Bekleyen Miktar",
    "佣金比例": "Komisyon Oranı",
    "获得佣金": "Komisyon Kazan",
    "完成订单": "Siparişi Tamamla",
    "请至少选择一项": "Lütfen en az bir seçenek belirleyin.",
    "请先选择星星": "Lütfen önce yıldız seçin.",
    "公告": "Duyuru",
    "邀请好友": "Arkadaşları Davet Et",
    "邀请好友赚取佣金": "Arkadaşları davet ederek komisyon kazanın.",
    "条款&协议": "Koşullar & Anlaşma",
    "注册协议": "Kayıt Anlaşması",
    "我已阅读并同意": "Okudum ve kabul ediyorum.",
    "加载中...": "Yükleniyor...",
    "没有更多数据": "Daha fazla veri yok.",
}