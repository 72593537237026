import { computed, onMounted, ref } from "vue"
import { t } from '@/i18n'
import { useLocalStorage } from '@vueuse/core'
import { get, post, put } from '../request'
import { useRoute, useRouter } from "vue-router"
import { dialog } from "@/components/WiDialog"
import { uploadBase64ImageFunc } from './basic'


export const authRef = useLocalStorage('authInfo', {})
export const isAuthRef = computed(() => !!authRef.value.access_token)
export const memberLocal = useLocalStorage('member', {})
const accountLocal = computed(()=> {
    return memberLocal.value&&memberLocal.value.account||{}
})

export var loginShowRef = ref(false)
export var registerShowRef = ref(false)

export function userModel(init) {
    const memberRef = computed(()=> { return memberLocal.value||{} })
    const accountRef = computed(()=> { return accountLocal.value||{} })
    const currentLevelRef = computed(()=> {
        return memberLocal.value&&memberLocal.value.memberLevel||{}
    })
    async function userInfoFunc() {
        let res = await get({url: '/v1/member/member/info'})
        if(res.code != 200) return
        memberLocal.value = res.data&&res.data.member
    }
    async function userUpdateFunc(data = {}) {
        let params = { id: memberRef.value.id }
        const res = await put({ url: '/v1/member/member/update', params, data }, { loading: true, toast: true })
        if(res.code != 200) return
        userInfoFunc()
    }
    async function userAvatarFunc(image) {
        if(!image) return
        uploadBase64ImageFunc(image).then(async (imgUrl) => {
            userUpdateFunc({ head_portrait: imgUrl })
        })
    }

    onMounted(()=> {
        if(init) {
            userInfoFunc()
        }
    })
    return { memberRef, accountRef, currentLevelRef, userInfoFunc, userUpdateFunc, userAvatarFunc }
}

export function signConfigModel(init) {
    const configRef = ref({})
    const phoneCodeListRef = ref([])
    const phoneCodeRef = ref({})

    const refresh = async () => {
        const res = await get({ url: '/v1/site/register-config' }, { loading: true })
        configRef.value = res.data || {}
        let __list = configRef.value.mobile_preg_list || []
        let __arr = []
        for(let i=0;i<__list.length;i++) {
            let __item = {
                text: __list[i].name,
                value: __list[i].code
            }
            __arr.push(__item)
        }
        phoneCodeListRef.value = __arr
        phoneCodeRef.value = (__arr || [])[0] || {}
    }

    onMounted(() => {
        if(init) {
            refresh()
        }
    })

    return { configRef, phoneCodeListRef, phoneCodeRef }
}

export function loginModel() {
    const { phoneCodeListRef, phoneCodeRef } = signConfigModel(true)

    const route = useRoute()
    const router = useRouter()

    let dataRef = ref({username: '', password: ''})

    async function loginFunc() {
        const data = {...dataRef.value, group: 'h5', mobile_code: phoneCodeRef.value&&phoneCodeRef.value.value}
        const res = await post({url: '/v1/site/login', data: data}, {loading: true, toast: true})
        if(res.code != 200) return
        
        loginShowRef.value = false
        authRef.value = res.data
        memberLocal.value = res.data&&res.data.member
        let next = decodeURI(route.query.next || '/')
        router.replace(next)
    }

    return { phoneCodeListRef, phoneCodeRef, dataRef, loginFunc }
}

export function logoutFunc() {
    dialog({title: t('提示'), content: t('你确定要登出当前账号吗？')}).then(()=> {
        _logout()
    })
}

function _logout() {
    authRef.value = {}
    memberLocal.value = {}
}

export function registerModel() {
    const { phoneCodeListRef, phoneCodeRef } = signConfigModel(true)
    const route = useRoute()
    const router = useRouter()

    let dataRef = ref({
        mobile: '', 
        password: '', 
        password_repetition: '', 
        invitation_code: route.query.invitation_code||route.query.promo_code||'', 
    })

    async function registerFunc() {
        const data = {...dataRef.value, group: 'h5', mobile_code: phoneCodeRef.value&&phoneCodeRef.value.value }
        const res = await post({url: '/v1/site/register', data: data}, {loading: true, toast: true})
        if(res.code != 200) return
        registerShowRef.value = false
        if(res.data&&res.data.access_token) {
            authRef.value = res.data
            memberLocal.value = res.data&&res.data.member
        }
        let next = decodeURI(route.query.next || '/')
        router.replace(next)
    }

    return { phoneCodeListRef, phoneCodeRef, dataRef, registerFunc }
}

export function changePassModel() {
    const router = useRouter()
    var dataRef = ref({
        old_password: '',
        password: '',
        password_repetition: '',
    })
    async function cheangeLoginFunc(type) {
        dataRef.value.group = 'h5'
        dataRef.value.type = type
        const res = await post({ url: '/v1/site/modify-pwd', data: dataRef.value }, { loading: true, toast: true })
        if (res.code != 200) return

        if(type == 'login_pwd') {
            _logout()
            router.replace('/')
        }else {
            router.back()
        }
    }
    return { dataRef, cheangeLoginFunc }
}