export default {
    "title": "🇫🇷 Français",

    "很棒的住宿体验": "Super expérience d'hébergement",
    "清爽干净，维护良好": "Propre, frais et bien entretenu",
    "很棒的酒店和员工": "Super hôtel et personnel",
    "价值符合预期": "La valeur est conforme aux attentes",
    "卓越的": "Exceptionnel",

    "首页": "Accueil",
    "预订": "Réservation",
    "关于": "À propos",
    "发现": "Découverte",
    "会员": "Membre",
    "推荐计划": "Programme de recommandation",
    "我的": "Mon compte",
    "个人": "Personnel",
    "推荐": "Recommandation",
    "事件": "Événements",
    "登出": "Déconnexion",
    "登录": "Connexion",
    "注册": "Inscription",
    "语言": "Langue",
    "忘记密码": "Mot de passe oublié",
    "取消": "Annuler",
    "确认": "Confirmer",
    "酒店": "Hôtel",
    "银行卡列表": "Liste des cartes bancaires",
    "银行列表": "Liste des banques",
    "持卡人姓名": "Nom du titulaire",
    "银行卡号": "Numéro de carte",
    "支付密码": "Mot de passe de paiement",
    "提交": "Soumettre",
    "Pix列表": "Liste Pix",
    "Pix类型": "Type de Pix",
    "Pix账号": "Compte Pix",
    "账号列表": "Liste des comptes",
    "网络": "Réseau",
    "地址": "Adresse",
    "备注": "Remarque",
    "充值金额": "Montant de recharge",
    "支付类型": "Type de paiement",
    "支付银行": "Banque de paiement",
    "USDT地址": "Adresse USDT",
    "二维码": "Code QR",
    "充值渠道": "Canal de recharge",
    "充值凭证": "Preuve de recharge",
    "立即登录": "Se connecter maintenant",
    "用户名": "Nom d'utilisateur",
    "密码": "Mot de passe",
    "邀请码": "Code d'invitation",
    "确认密码": "Confirmer le mot de passe",
    "手机": "Téléphone",
    "金额": "Montant",
    "新增": "Ajouter",
    "钱包余额": "Solde du compte",
    "单价": "Prix unitaire",
    "数量": "Quantité",
    "总额": "Total",
    "奖励": "Récompense",
    "手机号码": "Numéro de téléphone",
    "修改密码": "Modifier le mot de passe",
    "登录密码": "Mot de passe de connexion",
    "当前密码": "Mot de passe actuel",
    "新密码": "Nouveau mot de passe",
    "确认新密码": "Confirmer le nouveau mot de passe",
    "保存修改": "Enregistrer les modifications",
    "充值": "Recharge",
    "复制": "Copier",
    "分享链接": "Partager le lien",
    "升级": "Mise à niveau",
    "登录时间": "Heure de connexion",
    "信誉分": "Points de réputation",
    "提现": "Retrait",
    "提现账号": "Compte de retrait",
    "你确定要删除此账号吗": "Êtes-vous sûr de vouloir supprimer ce compte ?",
    "你确定要删除此地址吗": "Êtes-vous sûr de vouloir supprimer cette adresse ?",
    "提示": "Avertissement",
    "是": "Oui",
    "否": "Non",
    "全部": "Tout",
    "待处理": "En attente",
    "冻结中": "En cours de gel",
    "已完成": "Terminé",
    "我们的伙伴": "Nos partenaires",
    "关于我们": "À propos de nous",
    "创建时间": "Date de création",
    "你确定要登出当前账号吗？": "Êtes-vous sûr de vouloir vous déconnecter ?",
    "正在寻找酒店": "Recherche d'hôtels en cours",
    "旅程价格": "Prix du voyage",
    "佣金": "Commission",
    "搜索": "Recherche",
    "评分": "Évaluation",
    "评论": "Commentaire",
    "评论2": "Commentaire",
    "你还没有设置支付密码，请前往设置": "Vous n'avez pas encore défini de mot de passe de paiement, veuillez aller dans les paramètres",
    "请输入": "Veuillez entrer",
    "请选择": "Veuillez sélectionner",
    "账户明细": "Détails du compte",
    "等级": "Niveau",
    "每日订单": "Commandes quotidiennes",
    "设置": "Paramètres",
    "语言设置": "Paramètres de langue",
    "客服": "Service client",
    "投诉": "Réclamation",
    "投诉客户服务": "Réclamation au service client",
    "帮助": "Aide",
    "复制成功": "Copie réussie",
    "位置、中心": "Localisation, centre",
    "你好": "Bonjour",
    "我的账户": "Mon compte",
    "头像": "Avatar",
    "昵称": "Surnom",
    "设置昵称": "Définir le surnom",
    "输入酒店": "Saisir l'hôtel",
    "住宿问答": "Questions-réponses sur l'hébergement",
    "如果您想了解更多住宿信息，请查看其他房客的问题": "Si vous souhaitez en savoir plus sur l'hébergement, veuillez consulter les questions des autres clients.",
    "酒店问答_Q1": "Quelle est la différence entre une chambre double et une chambre à deux lits ?",
    "酒店问答_A1": "Une chambre double dispose d'un lit double, tandis qu'une chambre à deux lits dispose de deux lits simples. Si la chambre est appelée chambre double/chambre à deux lits, elle peut être configurée en l'un ou l'autre type. Cette information vous a-t-elle été utile ?",
    "酒店问答_Q2": "Que comprend le prix ?",
    "酒店问答_A2": "Vous pouvez vérifier si d'autres frais, tels que le petit-déjeuner, les taxes ou les frais de service, sont inclus. Ces informations peuvent également être trouvées dans l'e-mail de confirmation après votre réservation, que vous pouvez consulter dans votre compte.",
    "立即预订可享受酒店特别折扣": "Réservez maintenant pour bénéficier de remises spéciales sur l'hôtel",
    "最低": "Minimum",
    "折扣": "Remise",
    "订单时间": "Date de commande",
    "产品": "Produit",
    "订单数量": "Quantité de commandes",
    "提交订单": "Soumettre la commande",
    "可用余额": "Solde disponible",
    "冻结金额": "Montant gelé",
    "佣金比例": "Taux de commission",
    "获得佣金": "Gagner une commission",
    "完成订单": "Commande terminée",
    "请至少选择一项": "Veuillez sélectionner au moins une option",
    "请先选择星星": "Veuillez d'abord sélectionner une étoile",
    "公告": "Annonce",
    "邀请好友": "Inviter des amis",
    "邀请好友赚取佣金": "Invitez des amis pour gagner des commissions",
    "条款&协议": "Conditions & accords",
    "注册协议": "Accord d'inscription",
    "我已阅读并同意": "J'ai lu et j'accepte",
    "加载中...": "Chargement...",
    "没有更多数据": "Pas plus de données",
}