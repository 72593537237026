export default {
    "title": "🇹🇭 ไทย",

    "很棒的住宿体验": "ประสบการณ์การเข้าพักที่ยอดเยี่ยม",
    "清爽干净，维护良好": "สดชื่น สะอาด และดูแลอย่างดี",
    "很棒的酒店和员工": "โรงแรมและพนักงานที่ยอดเยี่ยม",
    "价值符合预期": "คุ้มค่าตามที่คาดหวัง",
    "卓越的": "โดดเด่น",

    "首页": "หน้าแรก",
    "预订": "จอง",
    "关于": "เกี่ยวกับ",
    "发现": "ค้นพบ",
    "会员": "สมาชิก",
    "推荐计划": "โปรแกรมแนะนำ",
    "我的": "ของฉัน",
    "个人": "ส่วนตัว",
    "推荐": "แนะนำ",
    "事件": "เหตุการณ์",
    "登出": "ออกจากระบบ",
    "登录": "เข้าสู่ระบบ",
    "注册": "ลงทะเบียน",
    "语言": "ภาษา",
    "忘记密码": "ลืมรหัสผ่าน",
    "取消": "ยกเลิก",
    "确认": "ยืนยัน",
    "酒店": "โรงแรม",
    "银行卡列表": "รายการบัตรธนาคาร",
    "银行列表": "รายการธนาคาร",
    "持卡人姓名": "ชื่อผู้ถือบัตร",
    "银行卡号": "หมายเลขบัตรธนาคาร",
    "支付密码": "รหัสผ่านการชำระเงิน",
    "提交": "ส่ง",
    "Pix列表": "รายการ Pix",
    "Pix类型": "ประเภท Pix",
    "Pix账号": "บัญชี Pix",
    "账号列表": "รายการบัญชี",
    "网络": "เครือข่าย",
    "地址": "ที่อยู่",
    "备注": "หมายเหตุ",
    "充值金额": "จำนวนเงินเติม",
    "支付类型": "ประเภทการชำระเงิน",
    "支付银行": "ธนาคารสำหรับการชำระเงิน",
    "USDT地址": "ที่อยู่ USDT",
    "二维码": "QR โค้ด",
    "充值渠道": "ช่องทางการเติมเงิน",
    "充值凭证": "หลักฐานการเติมเงิน",
    "立即登录": "เข้าสู่ระบบทันที",
    "用户名": "ชื่อผู้ใช้",
    "密码": "รหัสผ่าน",
    "邀请码": "รหัสเชิญ",
    "确认密码": "ยืนยันรหัสผ่าน",
    "手机": "โทรศัพท์มือถือ",
    "金额": "จำนวนเงิน",
    "新增": "เพิ่มใหม่",
    "钱包余额": "ยอดเงินในบัญชี",
    "单价": "ราคาต่อหน่วย",
    "数量": "จำนวน",
    "总额": "รวมทั้งหมด",
    "奖励": "รางวัล",
    "手机号码": "หมายเลขโทรศัพท์มือถือ",
    "修改密码": "เปลี่ยนรหัสผ่าน",
    "登录密码": "รหัสผ่านการเข้าสู่ระบบ",
    "当前密码": "รหัสผ่านปัจจุบัน",
    "新密码": "รหัสผ่านใหม่",
    "确认新密码": "ยืนยันรหัสผ่านใหม่",
    "保存修改": "บันทึกการเปลี่ยนแปลง",
    "充值": "เติมเงิน",
    "复制": "คัดลอก",
    "分享链接": "แชร์ลิงก์",
    "升级": "อัปเกรด",
    "登录时间": "เวลาที่เข้าสู่ระบบ",
    "信誉分": "คะแนนเครดิต",
    "提现": "ถอนเงิน",
    "提现账号": "บัญชีถอนเงิน",
    "你确定要删除此账号吗": "คุณแน่ใจหรือไม่ว่าต้องการลบบัญชีนี้?",
    "你确定要删除此地址吗": "คุณแน่ใจหรือไม่ว่าต้องการลบที่อยู่นี้?",
    "提示": "คำแนะนำ",
    "是": "ใช่",
    "否": "ไม่",
    "全部": "ทั้งหมด",
    "待处理": "รอดำเนินการ",
    "冻结中": "ถูกระงับ",
    "已完成": "เสร็จสิ้น",
    "我们的伙伴": "พันธมิตรของเรา",
    "关于我们": "เกี่ยวกับเรา",
    "创建时间": "เวลาที่สร้าง",
    "你确定要登出当前账号吗？": "คุณแน่ใจหรือไม่ว่าต้องการออกจากบัญชีปัจจุบัน?",
    "正在寻找酒店": "กำลังค้นหาโรงแรม",
    "旅程价格": "ราคาเดินทาง",
    "佣金": "ค่าคอมมิชชั่น",
    "搜索": "ค้นหา",
    "评分": "คะแนน",
    "评论": "ความคิดเห็น",
    "评论2": "ความคิดเห็น",
    "你还没有设置支付密码，请前往设置": "คุณยังไม่ได้ตั้งรหัสผ่านการชำระเงิน กรุณาไปที่การตั้งค่า",
    "请输入": "กรุณาใส่",
    "请选择": "กรุณาเลือก",
    "账户明细": "รายละเอียดบัญชี",
    "等级": "ระดับ",
    "每日订单": "คำสั่งประจำวัน",
    "设置": "ตั้งค่า",
    "语言设置": "การตั้งค่าภาษา",
    "客服": "บริการลูกค้า",
    "投诉": "ร้องเรียน",
    "投诉客户服务": "ร้องเรียนบริการลูกค้า",
    "帮助": "ช่วยเหลือ",
    "复制成功": "คัดลอกสำเร็จ",
    "位置、中心": "ตำแหน่ง, ศูนย์กลาง",
    "你好": "สวัสดี",
    "我的账户": "บัญชีของฉัน",
    "头像": "ภาพโปรไฟล์",
    "昵称": "ชื่อเล่น",
    "设置昵称": "ตั้งชื่อเล่น",
    "输入酒店": "กรอกชื่อโรงแรม",
    "住宿问答": "ถามตอบที่พัก",
    "如果您想了解更多住宿信息，请查看其他房客的问题": "หากคุณต้องการข้อมูลเพิ่มเติมเกี่ยวกับที่พัก โปรดดูคำถามของผู้เข้าพักคนอื่น ๆ",
    "酒店问答_Q1": "ห้องคู่และห้องเตียงเดี่ยวมีความแตกต่างกันอย่างไร?",
    "酒店问答_A1": "ห้องคู่มีเตียงคู่หนึ่ง ห้องเตียงเดี่ยวมีเตียงเดี่ยวสองเตียง หากห้องเรียกว่าห้องคู่/ห้องเตียงเดี่ยวสามารถตั้งค่าเป็นประเภทใดก็ได้ ข้อมูลนี้มีประโยชน์ไหม?",
    "酒店问答_Q2": "ราคา รวมอะไรบ้าง?",
    "酒店问答_A2": "คุณสามารถดูได้ว่ารวมค่าใช้จ่ายเพิ่มเติมเช่นอาหารเช้า ภาษี หรือค่าบริการหรือไม่ ข้อมูลเหล่านี้ยังสามารถพบได้ในอีเมลยืนยันหลังจากที่คุณทำการจอง คุณยังสามารถตรวจสอบการจองของคุณในบัญชีของคุณได้",
    "立即预订可享受酒店特别折扣": "จองทันทีเพื่อรับส่วนลดพิเศษจากโรงแรม",
    "最低": "ขั้นต่ำ",
    "折扣": "ส่วนลด",
    "订单时间": "เวลาสั่งซื้อ",
    "产品": "ผลิตภัณฑ์",
    "订单数量": "จำนวนคำสั่งซื้อ",
    "提交订单": "ส่งคำสั่งซื้อ",
    "可用余额": "ยอดเงินที่ใช้ได้",
    "冻结金额": "จำนวนเงินที่ถูกระงับ",
    "佣金比例": "อัตราค่าคอมมิชชั่น",
    "获得佣金": "ได้รับค่าคอมมิชชั่น",
    "完成订单": "คำสั่งซื้อที่เสร็จสมบูรณ์",
    "请至少选择一项": "กรุณาเลือกอย่างน้อยหนึ่งรายการ",
    "请先选择星星": "กรุณาเลือกดาวก่อน",
    "公告": "ประกาศ",
    "邀请好友": "เชิญเพื่อน",
    "邀请好友赚取佣金": "เชิญเพื่อนเพื่อรับค่าคอมมิชชั่น",
    "条款&协议": "ข้อกำหนดและเงื่อนไข",
    "注册协议": "ข้อตกลงการลงทะเบียน",
    "我已阅读并同意": "ฉันได้อ่านและเห็นด้วย",
    "加载中...": "กำลังโหลด...",
    "没有更多数据": "ไม่มีข้อมูลเพิ่มเติม",
}