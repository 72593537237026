export default {
    "title": "🇸🇦 العربية",

    "很棒的住宿体验": "تجربة إقامة رائعة",
    "清爽干净，维护良好": "نظيف ومنعش، وصيانة جيدة",
    "很棒的酒店和员工": "فندق رائع وموظفين ممتازين",
    "价值符合预期": "القيمة تتماشى مع التوقعات",
    "卓越的": "متميز",

    "首页": "الرئيسية",
    "预订": "حجز",
    "关于": "حول",
    "发现": "اكتشاف",
    "会员": "عضو",
    "推荐计划": "برنامج التوصية",
    "我的": "خاصتي",
    "个人": "شخصي",
    "推荐": "توصية",
    "事件": "حدث",
    "登出": "تسجيل الخروج",
    "登录": "تسجيل الدخول",
    "注册": "تسجيل",
    "语言": "لغة",
    "忘记密码": "نسيت كلمة المرور",
    "取消": "إلغاء",
    "确认": "تأكيد",
    "酒店": "فندق",
    "银行卡列表": "قائمة بطاقات البنك",
    "银行列表": "قائمة البنوك",
    "持卡人姓名": "اسم حامل البطاقة",
    "银行卡号": "رقم بطاقة البنك",
    "支付密码": "كلمة مرور الدفع",
    "提交": "إرسال",
    "Pix列表": "قائمة Pix",
    "Pix类型": "نوع Pix",
    "Pix账号": "حساب Pix",
    "账号列表": "قائمة الحسابات",
    "网络": "شبكة",
    "地址": "عنوان",
    "备注": "ملاحظات",
    "充值金额": "مبلغ إعادة الشحن",
    "支付类型": "نوع الدفع",
    "支付银行": "بنك الدفع",
    "USDT地址": "عنوان USDT",
    "二维码": "رمز الاستجابة السريعة",
    "充值渠道": "قناة إعادة الشحن",
    "充值凭证": "إثبات إعادة الشحن",
    "立即登录": "تسجيل الدخول الآن",
    "用户名": "اسم المستخدم",
    "密码": "كلمة المرور",
    "邀请码": "رمز الدعوة",
    "确认密码": "تأكيد كلمة المرور",
    "手机": "الهاتف",
    "金额": "المبلغ",
    "新增": "إضافة جديدة",
    "钱包余额": "رصيد المحفظة",
    "单价": "سعر الوحدة",
    "数量": "الكمية",
    "总额": "المجموع",
    "奖励": "مكافأة",
    "手机号码": "رقم الهاتف المحمول",
    "修改密码": "تغيير كلمة المرور",
    "登录密码": "كلمة مرور تسجيل الدخول",
    "当前密码": "كلمة المرور الحالية",
    "新密码": "كلمة مرور جديدة",
    "确认新密码": "تأكيد كلمة المرور الجديدة",
    "保存修改": "حفظ التغييرات",
    "充值": "إعادة شحن",
    "复制": "نسخ",
    "分享链接": "رابط المشاركة",
    "升级": "ترقية",
    "登录时间": "وقت الدخول",
    "信誉分": "نقاط السمعة",
    "提现": "سحب",
    "提现账号": "حساب السحب",
    "你确定要删除此账号吗": "هل أنت متأكد أنك تريد حذف هذا الحساب؟",
    "你确定要删除此地址吗": "هل أنت متأكد أنك تريد حذف هذا العنوان؟",
    "提示": "تنبيه",
    "是": "نعم",
    "否": "لا",
    "全部": "الكل",
    "待处理": "قيد المعالجة",
    "冻结中": "معلق",
    "已完成": "مكتمل",
    "我们的伙伴": "شركاؤنا",
    "关于我们": "معلومات عنا",
    "创建时间": "وقت الإنشاء",
    "你确定要登出当前账号吗？": "هل أنت متأكد أنك تريد تسجيل الخروج من هذا الحساب؟",
    "正在寻找酒店": "جاري البحث عن الفنادق",
    "旅程价格": "سعر الرحلة",
    "佣金": "عمولة",
    "搜索": "بحث",
    "评分": "تقييم",
    "评论": "تعليق",
    "评论2": "تعليق",
    "你还没有设置支付密码，请前往设置": "لم تقم بعد بإعداد كلمة مرور الدفع، يرجى الذهاب إلى الإعدادات",
    "请输入": "الرجاء إدخال",
    "请选择": "يرجى الاختيار",
    "账户明细": "تفاصيل الحساب",
    "等级": "درجة",
    "每日订单": "الطلبات اليومية",
    "设置": "إعدادات",
    "语言设置": "إعدادات اللغة",
    "客服": "خدمة العملاء",
    "投诉": "شكوى",
    "投诉客户服务": "تقديم شكوى لخدمة العملاء",
    "帮助": "مساعدة",
    "复制成功": "تم النسخ بنجاح",
    "位置、中心": "موقع، مركز",
    "你好": "مرحبا",
    "我的账户": "حسابي",
    "头像": "الصورة الشخصية",
    "昵称": "اسم الشهرة",
    "设置昵称": "تعيين اسم الشهرة",
    "输入酒店": "أدخل الفندق",
    "住宿问答": "أسئلة وأجوبة حول الإقامة",
    "如果您想了解更多住宿信息，请查看其他房客的问题": "إذا كنت ترغب في معرفة المزيد من المعلومات حول الإقامة، يرجى الاطلاع على أسئلة الضيوف الآخرين",
    "酒店问答_Q1": "ما الفرق بين غرفة مزدوجة وغرفة بسريرين منفصلين؟",
    "酒店问答_A1": "تحتوي الغرفة المزدوجة على سرير مزدوج واحد، بينما تحتوي غرفة السريرين المنفصلين على سريرين مفردين. إذا كانت الغرفة تُسمى غرفة مزدوجة/سريرين منفصلين، يمكن إعدادها كأي نوع. هل كانت هذه المعلومات مفيدة؟",
    "酒店问答_Q2": "ما الذي يتضمنه السعر؟",
    "酒店问答_A2": "يمكنك التحقق مما إذا كانت هناك رسوم إضافية مثل الإفطار أو الضرائب أو رسوم الخدمة. يمكن العثور على هذه المعلومات أيضًا في البريد الإلكتروني التأكيدي بعد الحجز، ويمكنك الاطلاع عليها في حسابك.",
    "立即预订可享受酒店特别折扣": "احجز الآن للحصول على خصومات خاصة على الفندق",
    "最低": "أدنى",
    "折扣": "خصم",
    "订单时间": "وقت الطلب",
    "产品": "منتج",
    "订单数量": "عدد الطلبات",
    "提交订单": "تقديم الطلب",
    "可用余额": "الرصيد المتاح",
    "冻结金额": "المبلغ المجمد",
    "佣金比例": "نسبة العمولة",
    "获得佣金": "تحقيق العمولة",
    "完成订单": "الطلبات المكتملة",
    "请至少选择一项": "يرجى اختيار عنصر واحد على الأقل",
    "请先选择星星": "يرجى اختيار النجوم أولاً",
    "公告": "إعلان",
    "邀请好友": "دعوة الأصدقاء",
    "邀请好友赚取佣金": "دعوة الأصدقاء لكسب عمولة",
    "条款&协议": "الشروط والأحكام",
    "注册协议": "اتفاقية التسجيل",
    "我已阅读并同意": "لقد قرأت ووافقت",
    "加载中...": "جارٍ التحميل...",
    "没有更多数据": "لا توجد بيانات أخرى",
}